import * as React from "react";
import { Link } from "gatsby";

// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function SummertimeMagic() {
  return (
    <Layout>
      <Helmet title="Summertime Magic | Childish Gambino" />

      <h1>Summertime Magic</h1>

      <h2>Childish Gambino</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <p>Guava Island</p>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>You feel like summertime</p>
          <p>You took this heart of mine</p>
          <p>You'll be my valentine in the summer, in the summer</p>
        </Verse>
        <Verse>
          <p>You are my only one</p>
          <p>Just dancin' having fun</p>
          <p>Out in the shining sun of the summer, of the summer</p>
        </Verse>
        <Chorus>
          <p>Do love me, do love me, do</p>
          <p>I love you</p>
          <p>Put no one else above you</p>
          <p>I need you</p>
          <p>Oh!</p>
        </Chorus>
        <Verse>
          <p>Is it summertime magic</p>
          <p>That makes me wanna dance all night long</p>
          <p>It's your summertime magic</p>
          <p>Make me feel this way</p>
          <p>Got be booed up girl</p>
          <p>Your my world</p>
          <p>Baby girl</p>
          <p>My whole world</p>
          <p>Oh </p>
        </Verse>
        <Verse>
          <p>You feel like summertime (oh, yes you do)</p>
          <p>You took this heart of mine (you know you did)</p>
          <p>You'll be my Valentine (Valentine)</p>
          <p>In the summer (in the summer)</p>
          <p>In the summer</p>
        </Verse>
        <Chorus>
          <p>Do love me, do love me, do</p>
          <p>I love you</p>
          <p>Put no one else above you</p>
          <p>I need you</p>
          <p>Oh!</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}














